import React from 'react'
import UpdateBrowser from 'layouts/UpdateBrowser'
import SignupForm from 'components/SignupForm'
import AuthFooter from 'components/AuthFooter';

const SignupRightSideBar = ({ isOldBrowser, isAuthenticated, onSubmit, isBtnDisabled }) => {
  const _UpdateBrowser = () =>
    (isOldBrowser && (<UpdateBrowser />))
  const _SignupForm = () => (!isOldBrowser && <SignupForm isAuthenticated={isAuthenticated} onSubmit={onSubmit} isBtnDisabled={isBtnDisabled} />)
  return (
    <div>
      {_UpdateBrowser()}
      {_SignupForm()}
      <AuthFooter />
    </div>
  )
}

export default SignupRightSideBar