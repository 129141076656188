export const FontSizeStyle = { fontSize: '16px' };
export const AddSchTitle = 'Add Scholarship';
export const SearchPlaceholder = 'Search...';
export const SearchWidth = 350;
export const headers = [
  { label: 'Name', key: 'ScholarshipName' },
  { label: 'URL', key: 'ScholarshipURL' },
  { label: 'Status', key: 'Status' },
  { label: 'Description', key: 'Description' },
  { label: 'Note', key: 'Note' },
  { label: 'Amount', key: 'RewardAmmount' },
  { label: 'Won', key: 'Won' },
  { label: 'Deadline', key: 'Deadline' },
  { label: 'Created', key: 'created' },
  { label: 'Updated', key: 'updated' },
];

export const CsvFileName = 'Scholarship_Report.csv';
