import { LockOutlined, UserOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { Navigate, Link } from 'react-router-dom';
import { Divider } from 'antd';
import { signinEmailName, signinEmailPlaceholder, signinEmailRules, signinHeaderId, signupTitle, signinPwdName, signinPwdPlaceholder, signinPwdRules, signinTitle, toDashboard } from 'data/authConstants';

const LoginForm = ({ isAuthenticated, onSubmit, isBtnDisabled }) => {
  if (isAuthenticated) {
    return <Navigate to={toDashboard} />;
  }
  return (
    <Form className='font-family-roboto login-form'
      onFinish={(values) => onSubmit(values)}
    >
      <h2 id={signinHeaderId}>{signinTitle}</h2>
      <Divider />
      <Form.Item
        name={signinEmailName}
        rules={signinEmailRules}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={signinEmailPlaceholder} />
      </Form.Item>
      <Form.Item
        name={signinPwdName}
        rules={signinPwdRules}
        style={{marginBottom: 0}}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={signinPwdPlaceholder}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      <div className='forgot-password'>
        <Link
          id="forgotPassword"
          to='/forgot-password'
          className='font-family-roboto'
        >Forgot password? Click here.</Link>
      </div>
      <Form.Item >
        <Button
          size='large'
          type="primary"
          htmlType="submit"
          id="signin-btn"
          className='font-family-roboto'
          disabled={isBtnDisabled}
        >
          {signinTitle}
        </Button>
        <span>&nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;</span>
        <Link
          id="sign-up"
          to='/signup'
          className='font-family-roboto'
        >{signupTitle}</Link>
      </Form.Item>
      <Divider />
    </Form>
  );
};
export default LoginForm;