import t from '../action-types';
const initialState = [];

function scholarshpReducer(state = initialState, action) {
  const { type, scholarshp, id } = action;

  switch (type) {
    case t.READ_SCH:
      return scholarshp;
    case t.CREATE_SCH:
      return [...state, scholarshp];
    case t.DELETE_SCH:
      return state.filter((item, index) => item.objectId !== id);
    case t.UPDATE_SCH:
      return state.map((item, index) =>
        item.objectId === id ? scholarshp : item
      );
    default:
      return state;
  }
}

export default scholarshpReducer;
