import React from 'react';
import { browsersList, updateBrowserAttention } from 'data/authConstants';
export default function UpdateBrowser() {
  const _browsersList = () => {
    return (<ul>
      {browsersList.map((list, index) => {
        return <li key={index}>{list}</li>
      })}
    </ul>)
  }
  return (
    <div id='update-browser' >
      {updateBrowserAttention}
      {_browsersList()}
    </div>
  );
}
