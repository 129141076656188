import types from '../action-types';

const initialState = {
  isAuthenticated: false,
  loading: true,
  currentUser: null,
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_USER:
      return { 
        isAuthenticated: true,
        currentUser: payload 
      };
    case types.IS_VALID_LOGIN:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        isAuthenticated: true,
        loading: false,
      };
    case types.AUTH_ERROR:
    case types.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        currentUser: null,
      };
    default:
      return { ...state };
  }
}

export default authReducer;
