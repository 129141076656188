import { Table } from 'antd';

const AntdTable = ({ arrColumns, tableData }) => {
  return (
    <Table
      pagination={{
        position: ['bottomRight'],
        defaultPageSize: 10,
        pageSizeOptions: [10, 20, 50, 100]
      }}
      size="small"
      rowKey="objectId" columns={arrColumns}
      dataSource={tableData}
    />
  )
}
export default AntdTable;