import React from 'react'
import authLogo from 'assets/img/TheScholarshipSystemLogoWhiteLetters.png'
import { authLeftNoteText, authLogoAltName } from 'data/authConstants'
import { WEBAPP_VERSION } from 'data/Constants'

const AuthLeftSideBar = () => {
  return (
    <div>
      <img id='form-login-logo' src={authLogo} alt={authLogoAltName} />
      <p id="login-left-note">
        {authLeftNoteText}
      </p>
      <p id="copyright">
        &copy;{new Date().getFullYear()} The Scholarship System<br />All rights reserved | Version {WEBAPP_VERSION}
      </p>
    </div>
  )
}
export default AuthLeftSideBar