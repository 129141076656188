import t from '../action-types';

const initialState = { msg: null, type: null };

function msgReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case t.warning:
    case t.info:
    case t.error:
    case t.success:
    case t.loading:
      return {
        ...state,
        msg: payload,
        type: type,
      };
    case t.CLEAR:
      return {
        msg: null,
        type: null,
      };
    default:
      return state;
  }
}

export default msgReducer;
