//Auth
export const authLogoAlt = 'Logo'
export const authNote = 'NOTE: This is the username and password you created when you registered using The Scholarship System iOS or Android App.'
export const authLeftColSpan = 8
export const authRightColSpan = 16

//Sign in
export const signinTitle = 'Sign In'

//Sign up
export const signupTitle = 'Sign Up'

//Update Browser
export const updateBrowserAttention =
  ' ATTENTION: Your current browser does not support the necessary functionality to run The Scholarship System application. Please use one of the following browsers:';
export const browsersList = [
  'Microsoft Edge v14+',
  'Firefox v54+',
  'Chrome v49+',
  'Safari v10.1+',
  'Opera v47+',
  'iOS Safari v10.2+',
  'Android Browser v4.4.4+',
  'Chrome for Android v61+',
];

export const WEBAPP_VERSION = '0.9.04';