import t from '../action-types';

export const warningMsg = (msg) => (dispatch) => {
  dispatch({
    type: t.warning,
    payload: msg,
  });
};
export const infoMsg = (msg) => (dispatch) => {
  dispatch({
    type: t.info,
    payload: msg,
  });
};
export const errorMsg = (msg) => (dispatch) => {
  dispatch({
    type: t.error,
    payload: msg,
  });
};
export const successMsg = (msg) => (dispatch) => {
  dispatch({
    type: t.success,
    payload: msg,
  });
};
export const loadingMsg = (msg) => (dispatch) => {
  dispatch({
    type: t.loading,
    payload: msg,
  });
};
export const clearMsg = () => (dispatch) => {
  dispatch({
    type: t.CLEAR,
  });
};
