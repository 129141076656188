export const userGrade = [
  { value: 'Freshman', label: 'Freshmen (High school)' },
  { value: 'Soph', label: 'Soph (High school)' },
  { value: 'Junior', label: 'Junior (High school)' },
  { value: 'Senior', label: 'Senior (High school)' },
  { value: '1st', label: '1st year (College)' },
  { value: '2nd', label: '2nd year (College)' },
  { value: '3rd', label: '3rd year (College)' },
  { value: '4th', label: '4th year (College)' },
  { value: '5th', label: '5th year (College)' },
];

export const userMajor = [
  { value: 'Arts and Humanities', label: 'Arts and Humanities' },
  {
    value: 'Arts, Visual, and Performing',
    label: 'Arts, Visual, and Performing',
  },
  {
    value: 'English Language and Literature',
    label: 'English Language and Literature',
  },
  {
    value: 'Languages, Literatures, and Linguistics',
    label: 'Languages, Literatures, and Linguistics',
  },
  { value: 'Philosophy and Religion', label: 'Philosophy and Religion' },
  { value: 'Business', label: 'Business' },
  { value: 'Health and Medicine', label: 'Business' },
  {
    value: 'Area, Ethnic, Cultural, and Gender Studies',
    label: 'Area, Ethnic, Cultural, and Gender Studies',
  },
  {
    value: 'Family and Consumer Sciences',
    label: 'Family and Consumer Sciences',
  },
  {
    value: 'Liberal Arts and Sciences, General Studies, and Humanities',
    label: 'Liberal Arts and Sciences, General Studies, and Humanities',
  },
  {
    value: 'Parks, Recreation, and Fitness',
    label: 'Parks, Recreation, and Fitness',
  },
  {
    value: 'Public and Social Services',
    label: 'Parks, Recreation, and Fitness',
  },
  { value: 'Law and Legal Studies', label: 'Law and Legal Studies' },
  { value: 'Military', label: 'Military' },
  {
    value: 'Public Administration and Social Services',
    label: 'Public Administration and Social Services',
  },
  {
    value: 'Security and Protective Services',
    label: 'Security and Protective Services',
  },
  {
    value: 'Theological Studies and Religious Vocations',
    label: 'Theological Studies and Religious Vocations',
  },
  {
    value: 'Agriculture and Related Sciences',
    label: 'Agriculture and Related Sciences',
  },
  { value: 'Architecture and Planning', label: 'Architecture and Planning' },
  {
    value: 'Biological and Biomedical Sciences',
    label: 'Biological and Biomedical Sciences',
  },
  {
    value: 'Communications Technologies',
    label: 'Communications Technologies',
  },
  {
    value: 'Computer and Information Sciences',
    label: 'Computer and Information Sciences',
  },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Engineering Technologies', label: 'Engineering' },
  { value: 'Math and Statistics', label: 'Math and Statistics' },
  {
    value: 'Natural Resources and Conservation',
    label: 'Natural Resources and Conservation',
  },
  { value: 'Physical Sciences', label: 'Physical Sciences' },
  { value: 'Science Technologies', label: 'Science Technologies' },
  {
    value: 'Communication and Journalism',
    label: 'Communication and Journalism',
  },
  { value: 'Education', label: 'Education' },
  { value: 'History', label: 'History' },
  { value: 'Library Science', label: 'Library Science' },
  { value: 'Psychology', label: 'Library Science' },
  { value: 'Social Sciences', label: 'Social Sciences' },
  {
    value: 'Trades and Personal Services',
    label: 'Trades and Personal Services',
  },
  { value: 'Construction Trades', label: 'Construction Trades' },
  {
    value: 'Mechanic and Repair Technologies',
    label: 'Mechanic and Repair Technologies',
  },
  {
    value: 'Personal and Culinary Services',
    label: 'Personal and Culinary Services',
  },
  {
    value: 'Precision Production Trades',
    label: 'Precision Production Trades',
  },
  {
    value: 'Transportation and Materials Moving',
    label: 'Transportation and Materials Moving',
  },
  { value: 'Other', label: 'Other' },
  { value: 'Undecided', label: 'Undecided' },
];

export const userState = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const user = [
  { label: 'Parent', value: 'Parent' },
  { label: 'Student', value: 'Student' },
]