import { Modal } from 'antd';
import { useState } from 'react';
import SettingForm from 'components/SettingForm';
import { useDispatch } from 'react-redux';
import { editSetting } from 'store';

const SettingModal = ({ accountSetting, setAccountSetting, userData }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const SettingModalTitle = "Account Setting";

  const handleOk = (values) => {
    dispatch(editSetting(values, userData))
    setLoading(true);
    setTimeout(() => {
      setAccountSetting(false);
      setLoading(false);
    }, 500);
  };
  const handleCancel = () => {
    setAccountSetting(false);
  };
  return (
    <>
      <Modal
        title={SettingModalTitle}
        open={accountSetting}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <SettingForm handleOk={handleOk} loading={loading} userData={userData} handleCancel={handleCancel} />
      </Modal>
    </>
  );
};
export default SettingModal;