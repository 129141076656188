import React from 'react';
import scholarshipSystemLogo from 'assets/img/TheScholarshipSystemLogoWhiteLetters.png';
import { Col, Layout, Row, Space } from 'antd';
import { TeamOutlined, HomeOutlined } from '@ant-design/icons';
import User from 'components/User';
import { Link } from 'react-router-dom';

const { Header } = Layout;

export default function DashboardHeader({ userName }) {
  return (
    <Header className='fixed-header' >
      <Row>
        <Col span={3}>
          <a href='/'>
            <img id='dashboard-logo' src={scholarshipSystemLogo} alt='logo' />
          </a>
        </Col>
        <Col span={18}>
          <User userName={userName} ></User>
        </Col>
        <Col span={3} style={{ float: "right" }} className='header-app-links'>
          <Space size={16}>
            <Link to='https://thescholarshipsystem.com' target='_blank'>
              <HomeOutlined /><span className='header-link-text'>Website</span>
            </Link>
            <Link to='https://member.thescholarshipsystem.com' target='_blank'>
              <TeamOutlined /><span className='header-link-text'>Course</span>
            </Link>
          </Space>
        </Col>
      </Row>
    </Header>
  );
}
