import { Button, Form, Input, Space } from 'antd';
const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};
const SettingForm = ({ handleOk, handleCancel, loading }) => (
  <Form
    name="settingForm"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={(values) => handleOk(values)}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Current Password"
      name="currentPwd"
      rules={[
        {
          required: true,
          message: 'Please input your Current Password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Form.Item
      label="New Password"
      name="newPwd"
      rules={[
        {
          required: true,
          message: 'Please input your New Password!',
        },
        {
          min: 8
        }
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Form.Item
      label="Confirm Password"
      name="confirmPwd"
      rules={[
        {
          required: true,
          message: 'Please input your Confirm Password!',
        },
        {
          min: 8
        }
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Space className='content-flex-end'>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} >
          Save
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
      </Form.Item>
    </Space>
  </Form>
);
export default SettingForm;