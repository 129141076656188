import React from 'react'
import UpdateBrowser from 'layouts/UpdateBrowser'
import LoginForm from 'components/LoginForm'
import AuthFooter from 'components/AuthFooter';

const LoginRightSideBar = ({
  isOldBrowser,
  isAuthenticated,
  onSubmit,
  isBtnDisabled 
}) => {
  const _UpdateBrowser = () =>
    (isOldBrowser && (<UpdateBrowser />))
  const _LoignForm = () => (!isOldBrowser && <LoginForm 
    isAuthenticated={isAuthenticated}
    onSubmit={onSubmit}
    isBtnDisabled={isBtnDisabled} />
  )
  return (
    <div>
      {_UpdateBrowser()}
      {_LoignForm()}
      <AuthFooter />
    </div>
  )
}

export default LoginRightSideBar