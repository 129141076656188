import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 58,
    }}
    spin
  />
);
const Spinner = () => (
  <Spin style={{
    margin: 'auto',
    display: 'block'
  }} indicator={antIcon} />)

export default Spinner;