import { combineReducers } from 'redux';
import auth from './auth';
import msg from './msg';
import scholarshp from './scholarship';

const rootReducer = combineReducers({
  msg,
  auth,
  scholarshp,
});
export default rootReducer;
