import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { Navigate, Link } from 'react-router-dom';
import { Divider } from 'antd';
import { signinEmailRules, signinHeaderId, signinTitle, toDashboard } from 'data/authConstants';

const ForgotPasswordForm = ({ isAuthenticated, onSubmit, isBtnDisabled }) => {
  if (isAuthenticated) {
    return <Navigate to={toDashboard} />;
  }
  return (
    <Form className='font-family-roboto login-form'
      onFinish={(values) => onSubmit(values)}
    >
      <h2 id={signinHeaderId}>Forgot Password</h2>
      <Divider />
      <Form.Item
        name='email'
        rules={signinEmailRules}
        style={{margin: '50px 0'}}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder='Email' />
      </Form.Item>
      <Form.Item
        style={{margin: '50px 0'}}
      >
        <Button
          size='large'
          type="primary"
          htmlType="submit"
          id="signin-btn"
          className='font-family-roboto'
          disabled={isBtnDisabled}
          style={{width: 130}}
        >
          Send Request
        </Button>
        <span>&nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;</span>
        <Link
          id="sign-up"
          to='/login'
          className='font-family-roboto'
        >{signinTitle}</Link>
      </Form.Item>
      <Divider />
    </Form>
  );
};
export default ForgotPasswordForm;