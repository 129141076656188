import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Button
} from 'antd';
import { userGrade, userState, userMajor } from 'data/profileConstants'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorMsg } from 'store';
import { toProfileEditor } from 'utils/changer';

const ProfileForm = ({ handleOk, loading, userData, handleCancel }) => {
  const dispatch = useDispatch()
  const [currentUserData, setCurrentUserData] = useState({});
  const [children, setChildren] = useState([]);

  useEffect(() => {
    setCurrentUserData(userData);
    userData.children && setChildren(userData.children.split(','))
  }, [userData])

  const onSubmit = values => {
    let data = Object.assign({}, currentUserData, values);
    handleOk(data);
  }

  const handleUserChange = val => {
    setCurrentUserData(prevData => ({
      ...prevData,
      user: val
    }))
  }

  const handleChildNameChange = (index, val) => {
    children[index] = val;
    setChildren(children);
    setCurrentUserData(prevData => ({
      ...prevData,
      children: children.join(',')
    }))
  }

  return (
    <Form
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      layout="horizontal"
      style={{
        maxWidth: 1000,
      }}
      onFinish={values => onSubmit(values)}
      fields={toProfileEditor(currentUserData)}
      autoComplete="off"
    >
      <Form.Item label="Name" name="name" rules={[
        {
          required: true,
        },
        {
          type: 'text',
          warningOnly: true,
        },
        {
          type: 'string',
          min: 1,
        },
      ]}>
        <Input />
      </Form.Item>
      <Form.Item label="User Type" name="user" rules={[
        {
          required: true,
        }]}>
        <Select onSelect={val => handleUserChange(val)}>
          <Select.Option value="Parent">Parent</Select.Option>
          <Select.Option value="Student">Student</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Email" name="email" rules={[
        {
          required: true,
        },
        {
          type: 'email',
          warningOnly: true,
        },
      ]}>
        <Input />
      </Form.Item>
      <Form.Item label="Birthday" name="birthday" rules={[
        {
          required: true,
        }
      ]}>
        <DatePicker className='width-100'
          format={'YYYY-MM-DD'}
        />
      </Form.Item>
      {currentUserData.user === 'Parent' ? <>
        {children.map((child, index) => {
          let i = index === 0 ? '' : index + 1;
          return (
            <div key={index}>
              <Form.Item
                label={`Child ${index + 1} Name`}
                rules={[
                  {
                    type: 'string',
                    warningOnly: true,
                  },
              ]}>
                <Input value={children[index]} onChange={e => handleChildNameChange(index, e.target.value)} />
              </Form.Item>
              <Form.Item label="1st Year Goal" name={`goalmoney${i}1`} rules={[
                {
                  type: 'number',
                  warningOnly: true,
                },
                {
                  type: 'number',
                  min: 0
                }
              ]}>
                <InputNumber className='width-100' />
              </Form.Item>
              <Form.Item label="2nd Year Goal" name={`goalmoney${i}2`} rules={[
                {
                  type: 'number',
                  warningOnly: true,
                },
                {
                  type: 'number',
                  min: 0
                }
              ]}>
                <InputNumber className='width-100' />
              </Form.Item>
              <Form.Item label="3rd Year Goal" name={`goalmoney${i}3`} rules={[
                {
                  type: 'number',
                  warningOnly: true,
                },
                {
                  type: 'number',
                  min: 0
                }
              ]}>
                <InputNumber className='width-100' />
              </Form.Item>
              <Form.Item label="4th Year Goal" name={`goalmoney${i}4`} rules={[
                {
                  type: 'number',
                  warningOnly: true,
                },
                {
                  type: 'number',
                  min: 0
                }
              ]}>
                <InputNumber className='width-100' />
              </Form.Item>
              <Form.Item label="5th Year Goal" name={`goalmoney${i}5`} rules={[
                {
                  type: 'number',
                  warningOnly: true,
                },
                {
                  type: 'number',
                  min: 0
                }
              ]}>
                <InputNumber className='width-100' />
              </Form.Item>
            </div>
          )
        })}
        <Space className='content-flex-end'>
          <Form.Item>
            <Button
              onClick={() => {
                children.length < 4
                  ? setChildren([...children, ''])
                  : dispatch(errorMsg('You must have 4 or fewer children.'));
              }}
            >
              Add Child
            </Button>
          </Form.Item>
        </Space>
        
      </> : <>
          <Form.Item label="Current Grade" name="grade" rules={[
            {
              required: true,
            }
          ]}>
            <Select
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              options={userGrade}
            />
          </Form.Item>
          <Form.Item label="GPA" name="gpa"
            rules={[
              {
                required: true,
              },
              {
                type: 'number',
                warningOnly: true,
                min: 0
              },
            ]}
          >
            <InputNumber className='width-100' />
          </Form.Item>
          <Form.Item label="State" name="state" rules={[
            {
              required: true,
            }
          ]}>
            <Select
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              options={userState}
            />
          </Form.Item>
          <Form.Item label="Intended Major" name="major" rules={[
            {
              required: true,
            }
          ]} >
            <Select
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              options={userMajor}
            />
          </Form.Item>
          <Form.Item label="1st Year Goal" name="goalmoney1" rules={[
            {
              required: true,
            },
            {
              type: 'number',
              warningOnly: true,
            },
            {
              type: 'number',
              min: 0
            }
          ]}>
            <InputNumber className='width-100' />
          </Form.Item>
          <Form.Item label="2nd Year Goal" name="goalmoney2" rules={[
            {
              required: true,
            },
            {
              type: 'number',
              warningOnly: true,
            },
            {
              type: 'number',
              min: 0
            }
          ]}>
            <InputNumber className='width-100' />
          </Form.Item>
          <Form.Item label="3rd Year Goal" name="goalmoney3" rules={[
            {
              required: true,
            },
            {
              type: 'number',
              warningOnly: true,
            },
            {
              type: 'number',
              min: 0
            }
          ]}>
            <InputNumber className='width-100' />
          </Form.Item>
          <Form.Item label="4th Year Goal" name="goalmoney4" rules={[
            {
              required: true,
            },
            {
              type: 'number',
              warningOnly: true,
            },
            {
              type: 'number',
              min: 0
            }
          ]}>
            <InputNumber className='width-100' />
          </Form.Item>
          <Form.Item label="5th Year Goal" name="goalmoney5" rules={[
            {
              required: true,
            },
            {
              type: 'number',
              warningOnly: true,
            },
            {
              type: 'number',
              min: 0
            }
          ]}>
            <InputNumber className='width-100' />
          </Form.Item>
        </>
      }
      <Form.Item label="Race" name="race" rules={[
        {
          required: true,
        },
        {
          type: 'text',
          warningOnly: true,
        },
        {
          type: 'string',
          min: 1,
        },
      ]}>
        <Input />
      </Form.Item>
      <Form.Item label="Course Member?" name="course" rules={[
        {
          required: true,
        }]}>
        <Select  >
          <Select.Option value="Yes">Yes</Select.Option>
          <Select.Option value="No">No</Select.Option>
        </Select>
      </Form.Item>
      <Space className='content-flex-end'>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} >
            Save
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
export default ProfileForm;