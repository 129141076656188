import { Modal } from 'antd';
import { useState } from 'react';
import ProfileForm from 'components/ProfileForm';
import { toProfilePrimitive } from 'utils/changer';
import { userSetting } from 'store';
import { useDispatch } from 'react-redux';

const ProfileModal = ({ openProfile, setOpenProfile, userData }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const profileModalTitle = "Edit Profile";

  const handleOk = (values) => {
    const updateUserData = toProfilePrimitive(userData, values);
    dispatch(userSetting(updateUserData))
    setLoading(true);
    setTimeout(() => {
      setOpenProfile(false);
      setLoading(false);
    }, 500);
  };
  const handleCancel = () => {
    setOpenProfile(false);
  };
  return (
    <>
      <Modal
        title={profileModalTitle}
        open={openProfile}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <ProfileForm handleOk={handleOk} loading={loading} userData={userData} handleCancel={handleCancel} />
      </Modal>
    </>
  );
};
export default ProfileModal;