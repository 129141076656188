import React from 'react'
import ForgotPasswordForm from 'components/ForgotPasswordForm'
import AuthFooter from 'components/AuthFooter';

const ForgotPasswordRightSideBar = ({
  isAuthenticated,
  onSubmit,
  isBtnDisabled 
}) => {
  return (
    <div>
      <ForgotPasswordForm
        isAuthenticated={isAuthenticated}
        onSubmit={onSubmit}
        isBtnDisabled={isBtnDisabled}
      />
      <AuthFooter />
    </div>
  )
}

export default ForgotPasswordRightSideBar