import React from "react";
import Spinner from "layouts/Spinner";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ component: Component }) => {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  if (loading)
    return (
      <div className="spinner-loading ">
        <Spinner />
      </div>
    );
  if (isAuthenticated) return <Component />;
  else return <Navigate to="/login" />;
};
export default PrivateRoute;
