export const autoRenewOptions = () => {
  const options = [];
  const order = ['st', 'nd', 'rd', 'th', 'th'];
  for (let i = 0; i < 5; i++) {
    options.push({
      value: `${i + 1}`,
      label: i + 1 + order[i] + ' year',
    });
  }
  return options;
};

export const deadlineActive = (schData) => {
  let deadlineActive = false;
  if (typeof schData.objectId !== 'undefined' && schData.objectId.length > 0) {
    deadlineActive = true;
  }
  return deadlineActive;
};

export const placeholderURL = 'https://www.url.com/';
