import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { register } from 'store';
import { Col, Row } from 'antd';
import AuthLeftSideBar from './AuthLeftSideBar';
import SignupRightSideBar from './SignupRightSideBar';
import { authLeftColSpan, authRightColSpan } from 'data/authConstants';
import { useState } from 'react';

export default function Sinup() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const [isBtnDisabled, setBtnDisabled]=useState(false);
  const onSubmit = async (values) => {
    const { name, user, email, password } = values;
    setBtnDisabled(true);
    await dispatch(register(name, user, email, password));
    setBtnDisabled(false);
  };

  const userAgent = window.navigator.userAgent;
  const isOldBrowser = userAgent.includes('MSIE') || userAgent.includes('Trident/');

  return (
    <div className="form-login-page">
      <Row>
        <Col className='form-login-left' span={authLeftColSpan}>
          <AuthLeftSideBar />
        </Col>
        <Col className='form-login-right' span={authRightColSpan}>
          <SignupRightSideBar
            isOldBrowser={isOldBrowser}
            isAuthenticated={isAuthenticated}
            onSubmit={onSubmit}
            isBtnDisabled={isBtnDisabled}
          />
        </Col>
      </Row>
    </div>
  )
}
