import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import { isValidLogin } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import Backendless from 'backendless';
// Coustom CSS
import 'assets/css/App.css';
// Antd CSS
import 'antd/dist/reset.css';
// Antd custom Css
import 'assets/css/custom-theme.less'
import Login from 'pages/Auth/Login';
import Signup from 'pages/Auth/Signup';
import Dashboard from './pages/dashboard';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import PrivateRoute from 'components/PrivateRoute';
import { Layout, message } from 'antd';
import { clearMsg } from 'store';

// init App with Backendless
Backendless.initApp(
  process.env.REACT_APP_APPLICATION_ID,
  process.env.REACT_APP_SECRET_KEY
);

const App = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth)
  
  useEffect(() => {
    dispatch(isValidLogin());
  }, [])
  const { msg, type } = useSelector((state) => state.msg)
  const afterClearMsg = () => {
    dispatch(clearMsg());
  }
  
  useEffect(() => {
    msg && message.open({
      type: type,
      content: msg,
      duration: 3,
      onClose: afterClearMsg()
    });
  }, [msg, type, afterClearMsg])

  return (
    <Layout className='layout-container' >
      <Routes>
        <Route path='/' element={<PrivateRoute component={Dashboard} loading={loading} />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>
    </Layout>
  );
};

export default App;
