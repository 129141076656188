import React from 'react'
import { CSVLink } from "react-csv";
import {
  PlusOutlined,
  DownloadOutlined,
  UserOutlined,
  LogoutOutlined,
  SyncOutlined,
  SettingOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Button, Space, Input, Col, Row } from 'antd';
import { FontSizeStyle, AddSchTitle, SearchPlaceholder, SearchWidth } from 'data/controlsBarConstants';
import { toCsv } from 'utils/changer';

export default function ControlsBar({ onLogout, showEditorModal, showProfileModal, allData, onRefresh, showAccountSettingModal, searchText, setSearchText }) {
  return (
    <Row id="controls-bar" >
      <Col flex={5} style={{
        display: 'flex',
        justifyContent: 'flex-start'
      }}>
        <Space.Compact>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder={SearchPlaceholder}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{
              width: SearchWidth,
            }}
          />
        </Space.Compact>
      </Col>
      <Col flex={5} style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Space  >
          <Button
            id='add'
            title='Add Scholarship'
            onClick={() => showEditorModal(AddSchTitle)}
          >
            <PlusOutlined style={FontSizeStyle} />
          </Button>
          <Button title="Export CSV">
            <CSVLink 
              {...toCsv(allData)}
            ><DownloadOutlined style={FontSizeStyle} /></CSVLink>
          </Button>
          <Button
            id='profile-edit'
            title='Edit Profile'
            onClick={() => showProfileModal()}
          >
            <UserOutlined style={FontSizeStyle} />
          </Button>
          <Button
            id='refresh'
            title='Refresh Scholarships'
            onClick={() => onRefresh()}
          >
            <SyncOutlined style={FontSizeStyle} />
          </Button>
          <Button
            id='account-settings'
            title='Account Settings'
            onClick={() => showAccountSettingModal()}
          >
            <SettingOutlined style={FontSizeStyle} />
          </Button>
          <Button id='logout' title='Logout' onClick={() => onLogout()}>
            <LogoutOutlined style={FontSizeStyle} />
          </Button>
        </Space>
      </Col>
    </Row>
  )
}
