import React from "react";
import { Link } from "react-router-dom";
import { Space } from "antd";
import {
  authFooterToLinkPlayGoogle,
  authFooterToLinkApple,
  authFooterToLinkGoogle,
} from "data/authConstants";
import { AppleFilled, PlayCircleFilled, ChromeFilled } from "@ant-design/icons";

const AuthFooter = () => {
  return (
    <div id="login-footer-app-links">
      <Space size={2}>
        <Link title="App Store" to={authFooterToLinkApple} target="_blank">
          <AppleFilled />
          <span className="link-title">
            App
            <br />
            Store
          </span>
        </Link>
        <Link
          title="Google Play"
          to={authFooterToLinkPlayGoogle}
          target="_blank"
        >
          <PlayCircleFilled />
          <span className="link-title">
            Google
            <br />
            Play
          </span>
        </Link>
        <Link
          title="Chrome Web Store"
          to={authFooterToLinkGoogle}
          target="_blank"
        >
          <ChromeFilled />
          <span className="link-title">
            Chrome
            <br />
            Web Store
          </span>
        </Link>
      </Space>
    </div>
  );
};

export default AuthFooter;
