import React from "react";
import { Link } from "react-router-dom";
import { Divider, Space } from "antd";
import { Footer } from "antd/es/layout/layout";
import { AppleFilled, PlayCircleFilled, ChromeFilled } from "@ant-design/icons";
import { WEBAPP_VERSION } from "data/Constants";

export default function DashboardFooter() {
  const copyright = new Date().getFullYear();
  return (
    <Footer className="fixed-footer">
      <Divider className="footer-divider" />
      <div id="footer">
        <div id="copyright">
          &copy; <span id="copy-year">{copyright}</span> The Scholarship System
          | All rights reserved | Version{" "}
          <span id="version">{WEBAPP_VERSION}</span>
        </div>
        <div id="footer-app-links">
          <Space size={16}>
            <Link
              title="App Store"
              to="https://itunes.apple.com/us/app/the-scholarship-system/id1102967717?mt=8"
              target="_blank"
            >
              <AppleFilled />{" "}
              <span className="footer-link-text">App Store</span>
            </Link>
            <Link
              title="Google Play"
              to="https://play.google.com/store/apps/details?id=com.www.thescholarshipsystem"
              target="_blank"
            >
              <PlayCircleFilled />{" "}
              <span className="footer-link-text">Google Play</span>
            </Link>
            <Link
              title="Chrome Web Store"
              to="https://chrome.google.com/webstore/detail/the-scholarship-system/kdhfhegimikbpmpnmkcdbmeijhgifodc?hl=en-US"
              target="_blank"
            >
              <ChromeFilled />{" "}
              <span className="footer-link-text">Chrome Web Store</span>
            </Link>
          </Space>
        </div>
      </div>
    </Footer>
  );
}
