export const authLeftNoteText =
  'NOTE: This is the username and password you created when you registered using The Scholarship System iOS or Android App.';
export const authLogoAltName = 'Logo';
export const authLeftColSpan = 8;
export const authRightColSpan = 16;
export const updateBrowserAttention =
  ' ATTENTION: Your current browser does not support the necessary functionality to run The Scholarship System application. Please use one of the following browsers:';
export const browsersList = [
  'Microsoft Edge v14+',
  'Firefox v54+',
  'Chrome v49+',
  'Safari v10.1+',
  'Opera v47+',
  'iOS Safari v10.2+',
  'Android Browser v4.4.4+',
  'Chrome for Android v61+',
];
export const signinTitle = 'Sign In';
export const signupTitle = 'Sign Up';
export const toDashboard = '/dashboard';
export const signinHeaderId = 'signin-header';
export const signinUserName = 'name';
export const signinUserNameRules = [
  {
    required: true,
    message: 'Please input your Name!'
  }
];
export const signinUserNamePlaceholder = 'Full Name'
export const signinEmailName = 'email';
export const signinEmailRules = [
  {
    type: 'email',
  },
  {
    required: true,
    message: 'Please input your email!',
  },
];
export const signinEmailPlaceholder = 'Email';
export const signinPwdName = 'password';
export const signinPwdRules = [
  {
    required: true,
    message: 'Please input your Password!',
  },
];
export const signinPwdPlaceholder = 'Password';
export const authFooterToLinkApple =
  'https://itunes.apple.com/us/app/the-scholarship-system/id1102967717?mt=8';
export const authFooterSrcApple =
  'https://lh3.googleusercontent.com/8lQluqsbtdyJgUAWLieKefCFbdnBFLZ7DJrV62knJWuUsS_kDs_pZ6KNxnuVKAuGxzjvmoSSDzlKYTZh7L9H4Q=s0';
export const authFooterToLinkPlayGoogle =
  'https://play.google.com/store/apps/details?id=com.www.thescholarshipsystem';
export const authFooterSrcPlayGoogle =
  'https://lh3.googleusercontent.com/aVb6xroEwVdcCSmwIUGTaM8qp2e7k4aG9BQq4FB3hXLItRPP6p-mCa6nmo0w9ONCkK2c61VbTh9amtuqizKc=s0';
export const authFooterToLinkGoogle =
  'https://chrome.google.com/webstore/detail/the-scholarship-system/kdhfhegimikbpmpnmkcdbmeijhgifodc?hl=en-US';
