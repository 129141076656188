import { LockOutlined, UserOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { Navigate, Link } from 'react-router-dom';
import { Divider } from 'antd';
import { signinUserName, signinUserNamePlaceholder, signinUserNameRules, signinEmailName, signinEmailPlaceholder, signinEmailRules, signinHeaderId, signinPwdName, signinPwdPlaceholder, signinPwdRules, signupTitle, toDashboard, signinTitle } from 'data/authConstants';

const SignupForm = ({ isAuthenticated, onSubmit, isBtnDisabled }) => {
  if (isAuthenticated) {
    return <Navigate to={toDashboard} />;
  }
  return (
    <Form className='font-family-roboto signup-form'
      onFinish={(values) => onSubmit(values)}
    >
      <h2 id={signinHeaderId}>{signupTitle}</h2>
      <Form.Item
        name={signinUserName}
        rules={signinUserNameRules}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={signinUserNamePlaceholder} />
      </Form.Item>
      <Form.Item name="user"  >
        <Select placeholder='Select user type'>
          <Select.Option value="Parent">Parent</Select.Option>
          <Select.Option value="Student">Student</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={signinEmailName}
        rules={signinEmailRules}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={signinEmailPlaceholder} />
      </Form.Item>
      <Form.Item
        name={signinPwdName}
        rules={signinPwdRules}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={signinPwdPlaceholder}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      <Form.Item >
        <Button
          size='large'
          type="primary"
          htmlType="submit"
          id="signin-btn"
          className='font-family-roboto'
          disabled={isBtnDisabled}
        >
          {signupTitle}
        </Button>
        <span>&nbsp;&nbsp;&nbsp;Have an account?&nbsp;&nbsp;&nbsp;</span>
        <Link
          id="sign-up"
          to='/login'
          className='font-family-roboto'
        >{signinTitle}</Link>
      </Form.Item>
      <Divider />
    </Form>
  );
};
export default SignupForm;