import React from 'react'

export default function User({ userName }) {
  return (
    <div id='user'>
      <h5>
        Welcome, <span id='name'>{userName}</span>!
      </h5>
    </div>
  )
}
