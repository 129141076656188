export default mirrorKeys({
  READ_SCH: null,
  READ_SCH_ERR: null,
  CREATE_SCH: null,
  DELETE_SCH: null,
  UPDATE_SCH: null,
  warning: null,
  info: null,
  error: null,
  success: null,
  loading: null,
  open: null,
  CLEAR: null,
  LOG_INFO: null,
  UPDATE_USER: null,
  IS_VALID_LOGIN: null,
  GET_CURRENT_USER: null,
  AUTH_ERROR: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAIL: null,
  LOGOUT: null,
});
function mirrorKeys(obj) {
  const mirroredObject = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      mirroredObject[key] = key;
    }
  }
  return mirroredObject;
}
